import React from "react";
import YouTube, { YouTubeProps } from 'react-youtube';

export const VideoPlayer = (props) => {

  // const onPlayerReady = (event) => {
  //   // access to player in all event handlers via event.target
  //   event.target.playVideo();
  // }

  const opts = {
    height: '480',
    width: '1080',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
      disablekb: 1,
      loop: 1,
      rel: 0,
      showinfo: 0,
      controls: 0,
      modestbranding: 1,
    },
  };

  return (
    <div id="videoplayer">
      <div className="container">
        <div className="row">
          <div className="col-md-12">

           <YouTube
              videoId="dt86RLRfpqs"
              opts={opts}
              // onReady={onPlayerReady}
           />;
           </div>
        </div>
      </div>
    </div>
  );
};
