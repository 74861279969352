import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore, Timestamp } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";

import "firebase/firestore";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAMC4IE5dnUv6LAHT9RPrzHax9PX273fM8",
  authDomain: "eudr-isonfarm.firebaseapp.com",
  projectId: "eudr-isonfarm",
  storageBucket: "eudr-isonfarm.appspot.com",
  messagingSenderId: "913626299949",
  appId: "1:913626299949:web:043fd50d351c4268a382a4",
  measurementId: "G-DZDSWCZ1TQ"
};

//init firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

//init service
const projectFirestore = getFirestore(app, "isonfarm");
const projectAuth = getAuth(app);
const projectStorage = getStorage(app);

//timestamp
const timestamp = Timestamp;

export { projectFirestore, projectAuth, projectStorage, timestamp };
