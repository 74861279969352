import React from "react";
import { Logo } from "./logo";


export const Header = (props) => {
  return (
    <header
     id="header"
    >
      <div
       className="intro"
       >
        <div
        //  className="overlay"
        >
          <div
           className="container-body"
          >
            <div className="logo">
              {/* <img src="img/logo.png" alt="" /> */}

            </div>
            <div className="menu">
                {/* Render children here */}
                {props.children}
            </div>

            <div className="intro-text">
              {/* Div to create the background space */}
              <div className="imagerow">
                {
                  props.data?.items.map((d, i) => (
                    <div key={`header-${i}`} className="imageoject">
                      <img src={d.img} alt={d.text} className="image"/>
                      <span className="image-subtitle">{d.text}</span>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
