import React from "react";

export const Cases = (props) => {
  return (
    <div id="studycases">
      <div className="container">
        <div className="row">
          <h1 className="title"> STUDY <text className="isonfarmgreen"> Case</text> </h1>
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src={props.data?.img} className="img-responsive" alt="" />{" "}
            <p>
              <strong>{props.data?.details}</strong>
            </p>
            <p className="readmore">
              <a href={props.data?.readmore} target="_blank">
                Read More
              </a>
            </p>
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>{props.data?.title}</h2>
              <h3>{props.data?.subtitle}</h3>
              {
                props.data?.paragraphs.map((p, i) => (
                  <p key={i}>{p}</p>
                ))
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
