import React from 'react';

export const Logo = () => {

  const url = "img/hammingbird.svg";
  const textIson = "ISON";
  const textFarming="FARMING";
  const textBottom = "DEFORESTATION";
  const textFree = "FREE";

  return (
    <div className="logo-container">
      <div className="logo-image">
        <img src={url} alt="Logo" style={{
          width: "60px",
          paddingLeft: "10px"
        }}
        />
      </div>
      <svg className="logo-svg" viewBox="0 0 200 200">
        <defs>
          <path
            id="textPathIson"
            d="M 50, 100
            a 50,50 0 1,1 120,0"
            stroke="red"
            stroke-width="4"
            fill="none"
          />
           <path
            id="textPathFarm"
            d="M 50, 100
               a 50,50 0 1,1 120,0"
            stroke="red"
            stroke-width="4"
            fill="none"
          />
          <path
            id="textPathBottom"
            d="M 35, 100
               a 50,50 0 1,0 150,0"
            stroke="red"
            stroke-width="4"
          />
          <path
            id="textPathBottomFree"
            d="M 35, 100
               a 50,50 0 1,0 150,0"
            stroke="red"
            stroke-width="4"
          />
        </defs>
        <text className="logo-text-top">
          <textPath href="#textPathIson"  startOffset="5%">
            {textIson}
          </textPath>
        </text>
        <text className="logo-text-top-green">
          <textPath href="#textPathFarm" startOffset="37%">
            {textFarming}
          </textPath>
        </text>
        <text className="logo-text-bottom">
          <textPath href="#textPathBottom" startOffset="10%">
            {textBottom}
          </textPath>
        </text>
        {/* <rect x="07" y="130" width="60" height="20" fill="white" rx="5" ry="5" /> */}
        <path
          id="backgroundPathFree"
          d="M 148, 160
             a 0,1 0 0,0 28,-36"
        //   d="M 35, 100
        //        a 50,50 0 1,0 150,0"
          stroke="white"
          strokeWidth="20"
          fill="none"
          className="logo-background-free"
        />
        <text className="logo-text-bottom-free">
          <textPath href="#textPathBottom" startOffset="70%">
            {textFree}
          </textPath>
        </text>
      </svg>
    </div>
  );
};

