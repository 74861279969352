import React from "react";


/* props:
 *  - Topbanner: Object
 *  - Topbanner.title: String
 *  - Topbanner.paragraph: String
 */
export const Topbanner = (props) => {
  const bannerStyle = {
    width: '100%',
    backgroundColor: 'white',
    padding: '0 0',
    textAlign: 'center',
    position: 'relative',
  };

  const bannerTitle = {
    margin: 0,
    fontSize: '2rem',
    color: '#333',
    textTransform: 'uppercase',
    fontWeight: 'bold',
  };

  const bannerParagraph = {
    margin: 0,
    fontSize: '2rem',
    color: '#9ecc2f',
    textTransform: 'uppercase',
    fontWeight: 'bold',
  };

  return (
    <div id="topbanner" style={bannerStyle} className="navbar navbar-fixed-top topbanner">
      <div className="container">
          <div style={bannerTitle}>
            {props.data?.title}
          </div>
          <div style={bannerParagraph}>
            {props.data?.paragraph}
          </div>
      </div>
    </div>
  );
};
