import React from "react";

export const WhatIs = (props) => {
  return (
    <div id="whatis">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src={props.data?.img} className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>{props.data?.title}</h2>
              <p>{props.data ? props.data?.p1 : "loading..."}</p>
              <p>{props.data ? props.data?.p2 : "loading..."}</p>
              <p>{props.data ? props.data?.p3 : "loading..."}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
