import { useState, useEffect } from "react";
import { projectFirestore } from "../firebase/config";
import { onSnapshot, doc, collection } from "firebase/firestore";

export const useDocument = (collection_name, id) => {
  const [document, setDocument] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const ref = collection(projectFirestore, collection_name)

    const d = doc(ref, id);

    const unsubscribe = onSnapshot(d,
      (snapshot) => {
        if (snapshot.data()) {
          console.log("Carregou: ", snapshot.data())
          setDocument({ ...snapshot.data(), id: snapshot.id });

          setError(null);
        } else {
          setError("No data exists");
        }
      },
      (err) => {
        console.error(err.message);
        setError("failed to get the document");
      }
    );

    return () => unsubscribe();
  }, [collection, id]);

  return { document, error };
};
