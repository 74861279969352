import React from "react";
import { Logo } from "./logo";

export const Navigation = (props) => {
  return (
    <nav id="menu"
      className="navbar navbar-default"
     >
      <div className="container ">
        <div
         className="navbar-header"
        >
        <Logo />

          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          {/* <a className="navbar-brand page-scroll" href="#page-top">
            ISONFARMING
          </a>{" "} */}

        </div>

        <div
          className="collapse navbar-collapse "
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right navbar-blur">
            <li>
              <a href="#features" className="page-scroll">
                Features
              </a>
            </li>
            <li>
              <a href="#whatis" className="page-scroll">
                About
              </a>
            </li>
            <li>
              <a href="#eudergenerator" className="page-scroll">
                EUDR Generator
              </a>
            </li>
            <li>
              <a href="#studycases" className="page-scroll">
                Study Cases
              </a>
            </li>
            <li>
              <a href="#services" className="page-scroll">
                Services
              </a>
            </li>
            {/* <li>
              <a href="#testimonials" className="page-scroll">
                Testimonials
              </a>
            </li>
            <li>
              <a href="#team" className="page-scroll">
                Team
              </a>
            </li> */}
            <li>
              <a href="#contact" className="page-scroll">
                Contact
              </a>
            </li>
            <li>
              <a href="https://eudr-isonfarmplat.web.app/login" className="btn" role="button">
                <p className="mylogin" >
                Login
                </p>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
