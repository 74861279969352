import React from "react";

export const NumberedParapgrahs = (props) => {
  return (
    <div id="np">
      <div className="container">
        <div className="row">
          <div className="col-xs-3 col-md-3 rowimage">
            {" "}
            <img src={props.data?.img} alt="" width={100}/>{" "}
          </div>
          <div className="col-xs-9 col-md-9 text-section">
            <div className="text-section">
              {
                props.data?.items.map((p, i) => (
                  <div className="text-item">
                    <div className="circle">{i+1}</div>
                    <p key={i}>{p}</p>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
