import React from "react";

export const ImageHorizontalList = (props) => {
  return (
    <div id="team" className="text-left">
      <div className="container">
        <div className="col-md-12">
          <h2 className="isonfarmgreen" >{props.data?.title}</h2>
          {
            props.data?.subtitle && <h3>{props.data?.subtitle}</h3>
          }
        </div>
        <div id="row">
          {props.data?.items
            ? props.data?.items.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4 col-sm-2 team">
                  <div className="thumbnail">
                    {" "}
                    <img src={d.img} alt={d.name} className="team-img" />
                    {/* <div className="caption">
                      <h4>{d.name}</h4>
                      <p>{d.job}</p>
                    </div> */}
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
