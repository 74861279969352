import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Topbanner } from "./components/topbanner";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Cases } from "./components/cases";
import { NumberedParapgrahs } from "./components/numberedparagraph";
import { WhatIs } from "./components/whatis";
import { Services } from "./components/services";
import { ImageHorizontalList } from "./components/imagehorizontalist";
import { Gallery } from "./components/gallery";
import { Testimonials } from "./components/testimonials";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import SmoothScroll from "smooth-scroll";
import "./App.css";

import JsonData from "./data/data.json";
import Policy from "./data/policy.json";


export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const Privacy = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <Topbanner data={landingPageData.Topbanner} />
      <Header data={landingPageData.Header}>
        <Navigation />
      </Header>

      <div className="container">
        <h2>Privacy Policy</h2>
        {

          Policy?.Content.map((item, index) => {
            return (
              <div key={index}>
                <h3>{index+1} {'. '} {item?.title}</h3>
                {
                  item?.text.map((text, index) => {
                      return (
                        /* Text must suport markup */
                        <p key={index} dangerouslySetInnerHTML={{__html: text}}></p>
                      )
                    }
                  )
                }
              </div>
            )
        })}
      </div>
      <Contact data={landingPageData.Contact} />
    </div>
  );
};

export default Privacy;
