import React from "react";

export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>{props.data?.title}</h2>
        </div>
        <div>
          <img src={props.data?.img} alt="services" width={200}/>
        </div>
        <div className="column">
          {props.data?.items.map((d, i) => (
                <div key={`service-${i}`}>
                  {" "}
                  <div className="service-desc">
                    <h3 className="isonfarmgreen">{d.title}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            }
        </div>
      </div>
    </div>
  );
};
