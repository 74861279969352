import React from "react";

/*
 * Features component
 * props:
 * - anchor: string: defines the id of the section, usefull for menu links
 * - data: object: contains the data to be rendered
 */
export const Features = (props) => {
  return (
    <div id={props?.anchor} className="text-center">
      <div className="container">
        <div className="col-md-9 col-md-offset-1 section-title">
          <h2>{props.data?.title}</h2>
        </div>
        <div className="feature-row">
          {props.data
            ? props.data?.items.map((d, i) => (
                <div key={`${d.title}-${i}`} className="col-xs-5 col-md-5 feature-item">
                  {" "}
                  <div className="feature-image">
                    <img className="feature-image" src={d.img} alt={d.text} />
                  </div>
                  <div className="feature-text">
                    <h3>{d.title}</h3>
                    {/* <p>{d.text}</p> */}
                  </div>
                </div>
              ))
            : "Loading..."}
        </div>
      </div>
    </div>
  );
};
