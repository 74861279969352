import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "./Home";
import Privacy from "./Privacy";
import Terms from "./Terms";
import FarmPublic from "./FarmPublic";
import CookieConsent from "react-cookie-consent";

import "./App.css";

const App = () => {

  return (
    <div>
     <CookieConsent
       enableDeclineButton
       location="bottom"
       buttonText="Accept"
       declineButtonText="Decline"
       cookieName="isonfarmingCookieConsent"
       style={{ background: "#2B373B" }}
       buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
       declineButtonStyle={{ fontSize: "14px" }}
       expires={60}
      >
        This website uses cookies to enhance the user experience. Please read our terms and conditions before accepting.
      </CookieConsent>


      <BrowserRouter>
        <Routes>
          <Route
            path="/*"
            element={<Home />}
          />
          <Route
            path="/qrcode/:id"
            element={<FarmPublic />}
          />
          <Route
              path="/privacy"
              element={<Privacy />}
            />
          <Route
              path="/terms"
              element={<Terms />}
            />
        </Routes>
      </BrowserRouter>

    </div>
  );
};

export default App;
